/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';
import 'bootstrap';

// start the Stimulus application
import './bootstrap';

// loads the jquery package from node_modules
import $ from 'jquery';
import SlimSelect from 'slim-select'
import tomselect from 'tom-select'
import TomSelect from "tom-select";

Window.initSlimSelectByClassName = initSlimSelectByClassName
Window.displayPaymentBooking = displayPaymentBooking
Window.displayPaymentMethod = displayPaymentMethod
Window.displayCustomerBooking = displayCustomerBooking
Window.coucou = coucou
Window.showSidebar = showSidebar
Window.initTomSelect = initTomSelect
Window.initTomSelectId = initTomSelectId


export function initTomSelect (className){
    // $('.'+className).addClass('d-none');
    let select = document.getElementsByClassName(className);
    let control = select.tomselect;
    if(control){
        console.log("destroy");
        control.destroy()
    }

    new TomSelect('.'+className, {});
}

export function initTomSelectId (idName){
    // $('.'+className).addClass('d-none');
    let select = document.getElementById(idName);
    let control = select.tomselect;
    if(control){
        console.log("destroy");
        control.destroy()
    }

    new TomSelect('#'+idName, {});
}

export function initSlimSelectByClassName (className){
    //console.log('#'+className);
    // return;
    new SlimSelect({
        select: className
    })
}

export function displayPaymentBooking(){
    Window.initSlimSelectByClassName("#payment_booking")
}

export function displayPaymentMethod(){
    Window.initSlimSelectByClassName("#payment_paymentMethod")
}

export function displayCustomerBooking(){
    Window.initSlimSelectByClassName("#booking_customer")
}

export function coucou(){
    console.log("COUCOU")
}

export function showSidebar(){
    $('#sidebarMenu').removeClass("collapse");
}